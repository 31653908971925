import React from 'react'
import cx from 'classnames'

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  containerClass?: string
  error?: string
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  function InputForwarded(props, ref) {
    const {
      containerClass,
      className,
      name,
      error,
      type = 'text',
      ...restProps
    } = props
    return (
      <div className={containerClass}>
        <input
          {...restProps}
          ref={ref}
          name={name}
          type={type}
          className={cx('input', { error: Boolean(error) }, className)}
        />
        {!!error && (
          <span className="text-xs font-semibold text-red-400">{error}</span>
        )}
        <style jsx>{`
          .input {
            @apply bg-white py-4 px-6 font-inter;
            border: 1px solid black;
            border-radius: 4px;
            width: inherit;
          }

          .input.error {
            @apply border-red-400 text-red-400 outline-red-400;
          }
        `}</style>
      </div>
    )
  }
)

export default Input
