import cx from 'classnames'
import Link from 'next/link'

type TitleProps = {
  title: string
  slug?: string
  className?: string
}

function Title(props: TitleProps) {
  const { title, slug, className } = props

  return (
    <>
      {slug ? (
        <Link href={`/articles/${slug}`}>
          <a className={cx('title', className)}>{title}</a>
        </Link>
      ) : (
        <h1 className={cx('title', className)}>{title}</h1>
      )}
      <style jsx>{`
        .title {
          @apply font-inter font-extrabold;
          font-size: 45px;
          line-height: 55px;
        }

        @screen lg {
          .title {
            font-size: 75px;
            line-height: 82px;
          }
        }
      `}</style>
    </>
  )
}
export default Title
