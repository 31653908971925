import cx from 'classnames'

type AuthBoxProps = {
  children: React.ReactNode
  className?: string
}
function FormBox(props: AuthBoxProps) {
  const { children, className } = props
  return (
    <div className={cx('box', className)}>
      {children}
      <style jsx>{`
        .box {
          @apply mx-auto mt-20 flex flex-col items-start py-10 px-8;
          border: 1px solid black;
          box-sizing: border-box;
          border-radius: 15px;
          width: 590px;
          max-width: 90%;
        }

        @screen lg {
          .box {
            @apply p-12;
          }
        }
      `}</style>
    </div>
  )
}

export default FormBox
