import {
  GetServerSideProps,
  NextApiRequest,
  NextApiResponse,
  NextPage,
} from 'next'
import Router, { useRouter } from 'next/router'
import { useForm } from 'react-hook-form'
import Link from 'next/link'

import FormBox from '../components/FormBox'
import Button from '../components/Button'
import Container from '../components/Container'
import Input from '../components/Input'
import Layout from '../components/Layout'
import Title from '../components/Title'
import { useAuthContext } from '../context/Auth'
import { getLoginSession } from '../utils/auth/auth'
import { getServerSideProps as getSSRProps } from '../utils/settings'

type LoginFormInputs = {
  username: string
  password: string
}

type FormError = {
  form?: string
}

const Login: NextPage = () => {
  const { query } = useRouter()

  const context = useAuthContext()

  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors, isValid },
  } = useForm<LoginFormInputs & FormError>({
    mode: 'onChange',
  })

  // @ts-ignore
  async function onSubmit(...args) {
    clearErrors('form')
    return handleSubmit(async (data) => {
      try {
        clearErrors('form')

        const res = await fetch('/api/login', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            username: data.username,
            password: data.password,
          }),
        })

        const jsonData = await res.json()

        if (res.status === 200) {
          const redirect = query.redirect as string

          context.changeData({ user: jsonData.user })
          Router.push(redirect || '/')
        } else {
          throw new Error(
            jsonData.message || jsonData.data || 'Something went wrong'
          )
        }
      } catch (error: any) {
        setError('form', {
          type: 'manual',
          message: error?.message,
        })
      }
    })(...args)
  }

  return (
    <Layout>
      <Container className="flex flex-col py-32">
        <Title title="Welcome Back" className="text-center" />
        <form onSubmit={onSubmit}>
          <FormBox>
            <h3 className="mb-5 font-inter text-2xl font-bold">Login</h3>
            <Input
              placeholder="*Email Address"
              containerClass="w-full mb-6"
              error={errors.username?.message}
              {...register('username', {
                required: 'This field is required',
              })}
            />
            <div className="mb-6 flex w-full flex-col">
              <Input
                placeholder="*Password"
                containerClass="w-full"
                type="password"
                error={errors.password?.message}
                {...register('password', {
                  required: 'This field is required',
                })}
              />
              <Link href="/forgot-password">
                <a className="ml-auto mt-1.5 hover:underline">
                  Forgot password
                </a>
              </Link>
            </div>

            {errors['form']?.message && (
              <p className="mb-6 text-base font-semibold text-red-400">
                {errors['form'].message}
              </p>
            )}

            <div className="flex flex-col gap-4 lg:flex-row lg:items-center lg:gap-8">
              <Button disabled={!isValid}>Submit</Button>

              <div className="font-inter">
                Not a member?{' '}
                <Link
                  href={
                    query.redirect
                      ? `/signup?redirect=${query.redirect}`
                      : '/signup'
                  }
                  passHref
                >
                  <a className="cursor-pointer font-bold underline hover:no-underline">
                    Sign Up
                  </a>
                </Link>
              </div>
            </div>
          </FormBox>
        </form>
      </Container>
    </Layout>
  )
}

export const getServerSideProps: GetServerSideProps = async function ({
  req,
  res,
}) {
  const session = await getLoginSession(
    req as NextApiRequest,
    res as NextApiResponse
  )
  if (session) {
    return {
      redirect: {
        permanent: false,
        destination: '/',
      },
      props: {},
    }
  }

  const pageProps = await getSSRProps(
    req as NextApiRequest,
    res as NextApiResponse
  )
  return pageProps
}

export default Login
